@import "../../styles/variables.scss";
@import "../../styles/mediaQueries.scss";

.uploadCosmos {
  width: 6rem;
  position: fixed;
  right: 11rem;
  top: 1.5rem;
  z-index: 99;
  cursor: pointer;

  @include mq500 {
    width: 5rem;
    top: 2rem;
  }
}

.uploadDirection {
  position: fixed;
  z-index: 99;
  right: 18rem;
  top: 1rem;
  font-size: 2rem;
  color: $purple;

  @include mq500 {
    font-size: 1.7rem;
    right: 17rem;
  }
}
