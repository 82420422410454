@import "../../styles/variables.scss";
@import "../../styles/mediaQueries.scss";

.signInPage {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 2rem;

  h1 {
    margin: 0 0 2rem;
    color: $purple;
  }

  form {
    display: flex;
    flex-direction: column;
    width: 50rem;

    input,
    button {
      margin-bottom: 2rem;
      border: none;
      border-radius: 0.5rem;
      padding: 1rem;
    }

    button {
      background: white;
      color: $purple;
      width: 50%;
      margin: 0 auto 2rem;
      font-size: 2.5rem;
      cursor: pointer;
    }

    @include mq900 {
      width: 40rem;
    }

    @include mq500 {
      width: 80%;
    }
  }

  a {
    text-decoration: none;
    color: $purple;
    font-size: 2.5rem;
  }
}
