@import "../../../styles/variables.scss";
@import "../../../styles/mediaQueries.scss";

.letterFormModal {
  position: absolute;
  padding: 4rem 0 2rem;
  border: 1px solid $purple;
  border-radius: 0.5rem;
  width: 90%;
  min-height: 50vh;
  top: 14rem;
  left: 5%;
  z-index: 99;

  .closeLetterFormModal {
    position: absolute;
    top: 1rem;
    right: 1rem;
    border: none;
    background: none;
    color: $purple;
    font-size: 2rem;
    cursor: pointer;
  }

  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 90%;
    min-height: 50vh;
    margin: 0 auto;

    textarea {
      min-height: 20vh;
    }

    input,
    textarea {
      margin-bottom: 3rem;
    }

    input,
    textarea,
    button {
      border-radius: 0.5rem;
      border: none;
      padding: 1rem;
      font-size: 2rem;
    }

    button {
      width: 12rem;
      margin: 0 auto;
      padding: 1.5rem;
      background: white;
      color: $purple;
      cursor: pointer;
    }
  }
}
