@import "./mediaQueries.scss";
@import "./variables.scss";

html {
  font-size: 62.5%;
  font-family: "Nanum Pen Script", sans-serif;
  background: $pastelPurple;
}

img,
video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.wrapper {
  width: 90%;
  margin: 0 auto;
}

p {
  white-space: pre-wrap;
}
