@import "../../styles/variables.scss";
@import "../../styles/mediaQueries.scss";

.mainPage {
  display: flex;
  height: 100vh;

  .mom {
    min-width: 57rem;
    opacity: 0;

    &.show {
      opacity: 1;
      transition: opacity 3s;
    }

    @include mq1000 {
      min-width: unset;
      width: 28rem;
      margin: 8.5rem auto 0;

      img {
        border-radius: 0.5rem;
      }
    }
  }

  .container {
    width: 100%;

    .mainLetter {
      margin-top: 20rem;
      font-size: 4.5rem;
      color: $purple;

      p {
        margin: 0 7rem;
        line-height: 5rem;
        text-align: center;

        @include mq1000 {
          line-height: 4rem;
        }

        @include mq500 {
          line-height: 3.6rem;
        }
      }

      .cosmos {
        width: 9rem;
        margin: 1rem auto 0;
        -moz-animation: spin 4s linear infinite;
        -webkit-animation: spin 4s linear infinite;
        animation: spin 4s linear infinite;

        @include mq1000 {
          width: 6rem;
        }

        @include mq500 {
          width: 4.5rem;
        }

        @-moz-keyframes spin {
          100% {
            -moz-transform: rotate(360deg);
          }
        }
        @-webkit-keyframes spin {
          100% {
            -webkit-transform: rotate(360deg);
          }
        }
        @keyframes spin {
          100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
          }
        }
      }

      @include mq1000 {
        margin-top: 1rem;
        font-size: 3.5rem;
      }

      @include mq500 {
        font-size: 3.2rem;
      }
    }
  }

  @include mq500 {
    height: unset;
  }

  .signOut {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;

    p {
      font-size: 3rem;
    }

    button {
      padding: 2rem;
      border-radius: 0.5rem;
      border: none;
      color: $purple;
      font-size: 3rem;
      cursor: pointer;
    }
  }
}
