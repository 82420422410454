@import "../../styles/variables.scss";
@import "../../styles/mediaQueries.scss";

.menu {
  visibility: hidden;

  &.showMenu {
    visibility: visible;
  }

  button {
    border-radius: 0.5rem;
    border: none;
    color: $purple;
    position: fixed;
    right: 2rem;
    top: 2rem;
    z-index: 999;
    background: white;
    font-size: 2.2rem;
    font-weight: bold;
    padding: 1rem;
    cursor: pointer;
  }

  ul {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 998;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    list-style: none;
    font-size: 4.5rem;
    background: $pastelPurple;
    visibility: hidden;
    padding: 0;
    margin: 0;

    li {
      width: 33%;
      text-align: center;

      a {
        text-decoration: none;
        color: $purple;
      }
    }

    &.show {
      visibility: visible;
    }
  }
}
