@import "../../styles/variables.scss";
@import "../../styles/mediaQueries.scss";

.recipesPage {
  min-height: 100vh;

  .back {
    background: white;
    border-radius: 0.5rem;
    border: none;
    position: fixed;
    z-index: 99;
    top: 2rem;
    left: 2rem;
    font-size: 2.2rem;
    font-weight: bold;
    color: $purple;
    padding: 1rem;
    cursor: pointer;
  }

  .recipesHeader {
    text-align: center;
    font-size: 4.5rem;
    padding: 7.5rem 0 1.5rem;
    color: $purple;

    p {
      margin: 0;
    }

    @include mq500 {
      font-size: 3.5rem;
    }
  }

  .recipes {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 80%;
    margin: 0 auto;

    &.showRecipeDetail {
      justify-content: center;
    }

    .searchedRecipesList {
      input {
        width: 100%;
        padding: 1rem;
        border-radius: 0.5rem;
        border: 1px solid $purple;
        font-size: 3rem;
        margin-bottom: 3rem;
      }

      ul {
        list-style: none;
        padding: 0;
        text-align: center;
        margin: 0 0 3rem;

        li {
          font-size: 3.5rem;
          border: 1px solid $purple;
          border-radius: 0.5rem;
          margin: 0 0.2rem 3rem;

          a {
            display: block;
            width: 100%;
            padding: 1rem;
            text-decoration: none;
            color: $purple;
          }
        }
      }
    }

    .recipeName {
      font-size: 4.5rem;
      border: 1px solid $purple;
      margin: 0 0.2rem 3rem;
      border-radius: 0.5rem;
      cursor: pointer;

      a {
        margin: 0;
        color: $purple;
        text-decoration: none;
        display: block;
        width: 100%;
        padding: 2rem;

        @include mq500 {
          padding: 1rem;
        }
      }

      @include mq500 {
        font-size: 3.5rem;
        text-align: center;
      }
    }

    @include mq500 {
      width: 90%;
      flex-direction: column;
    }
  }
}
