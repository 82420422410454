@import "../../styles/variables.scss";
@import "../../styles/mediaQueries.scss";

.lettersPage {
  min-height: 100vh;

  .lettersHeader {
    text-align: center;
    font-size: 4.5rem;
    padding: 7.5rem 0 1.5rem;
    color: $purple;

    p {
      margin: 0;
    }

    @include mq500 {
      font-size: 3.5rem;
    }
  }

  .letters {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 80%;
    margin: 0 auto;

    .letter {
      width: 35rem;
      font-size: 2rem;
      margin: 0 0.2rem 3rem;
      padding: 2rem;
      border: 1px solid $purple;
      border-radius: 0.5rem;
      position: relative;

      .buttonContainer {
        position: absolute;
        top: 1rem;
        right: 0;

        button {
          border: none;
          background: none;
          color: $purple;
          cursor: pointer;

          &:last-child {
            margin-left: 2rem;
          }
        }
      }

      p {
        margin: 2rem 0;
        line-height: 2.5rem;

        &:first-child,
        &:last-child {
          margin: 0;
        }

        @include mq500 {
          font-size: 2.4rem;
          line-height: 2.9rem;
        }
      }

      @include mq900 {
        width: 100%;
      }
    }

    @include mq900 {
      justify-content: center;
    }

    @include mq500 {
      width: 90%;
    }
  }
}
