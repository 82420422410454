@mixin mq1000 {
  @media (max-width: 1000px) {
    @content;
  }
}

@mixin mq900 {
  @media (max-width: 900px) {
    @content;
  }
}

@mixin mq768 {
  @media (max-width: 768px) {
    @content;
  }
}

@mixin mq500 {
  @media (max-width: 500px) {
    @content;
  }
}
