@import "../../styles/variables.scss";
@import "../../styles/mediaQueries.scss";

.photoEnlarged {
  background: $pastelPurple;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;

  .closeEnlargedPhoto {
    border-radius: 0.5rem;
    border: none;
    color: $purple;
    z-index: 99;
    background: white;
    font-size: 2.2rem;
    font-weight: bold;
    padding: 0.3rem 1rem;
    position: absolute;
    left: 1rem;
    top: 1rem;
    cursor: pointer;
  }

  img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
}
