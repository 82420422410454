@import "../../styles/variables.scss";
@import "../../styles/mediaQueries.scss";

.songsPage {
  min-height: 100vh;

  .songsHeader {
    text-align: center;
    font-size: 4.5rem;
    padding: 7.5rem 0 1.5rem;
    color: $purple;

    p {
      margin: 0;
    }

    @include mq500 {
      font-size: 3.5rem;
    }
  }

  .repeatAllSongs {
    margin: 0 auto 2rem;
    display: block;
    background: white;
    font-size: 2.2rem;
    font-weight: bold;
    padding: 1rem;
    border-radius: 0.5rem;
    border: none;
    color: $purple;
    cursor: pointer;
  }

  .songs {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 80%;
    margin: 0 auto;

    .song {
      width: 35rem;
      font-size: 2rem;
      margin: 0 0.2rem 3rem;
      padding: 2rem;
      border: 1px solid $purple;
      border-radius: 0.5rem;
      position: relative;

      .buttonContainer {
        position: absolute;
        top: 1.5rem;
        right: 1rem;

        button {
          border: none;
          background: none;
          color: $purple;
          cursor: pointer;

          &:last-child {
            margin-left: 2rem;
          }
        }
      }

      p {
        margin: 1rem 0;
        line-height: 2.5rem;

        &:first-of-type,
        &:last-child {
          margin: 0;
        }

        @include mq500 {
          font-size: 2.4rem;
          line-height: 2.9rem;
        }
      }

      @include mq900 {
        width: 100%;
      }
    }

    @include mq900 {
      justify-content: center;
    }

    @include mq500 {
      width: 90%;
    }
  }
}
