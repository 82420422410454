@import "../../styles/variables.scss";
@import "../../styles/mediaQueries.scss";

.activitiesPage {
  min-height: 100vh;

  .activitiesHeader {
    text-align: center;
    font-size: 4.5rem;
    padding: 7.5rem 0 1.5rem;
    color: $purple;

    p {
      margin: 0;
    }

    @include mq500 {
      font-size: 3.5rem;
    }
  }

  .numInput {
    width: 80%;
    margin: 0 auto;
    display: block;
    padding: 1rem;
    border-radius: 0.5rem;
    border: 1px solid $purple;
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }

  .activities {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 80%;
    margin: 0 auto;

    .activity {
      width: 28rem;
      padding: 2rem;
      border: 1px solid $purple;
      border-radius: 0.5rem;
      margin-bottom: 2rem;

      p {
        color: $purple;
        font-size: 2.5rem;

        @include mq768 {
          margin: 0;
          font-size: 2.2rem;
        }
      }

      ul {
        padding: 0;

        li {
          list-style: none;
          margin-bottom: 0.7rem;
          font-size: 2rem;
        }
      }

      @include mq768 {
        width: 100%;
        padding: 1rem 1.5rem;
        margin-bottom: 1rem;
      }
    }
  }
}
