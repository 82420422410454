@import "../../../styles/variables.scss";
@import "../../../styles/mediaQueries.scss";

.back {
  border-radius: 0.5rem;
  border: none;
  color: $purple;
  position: fixed;
  left: 2rem;
  top: 2rem;
  z-index: 99;
  background: white;
  font-size: 2.2rem;
  font-weight: bold;
  padding: 1rem;
  text-decoration: none;
}

.groupedVideos {
  display: flex;
  padding-top: 9rem;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 90%;
  margin: 0 auto 4rem;

  @include mq500 {
    width: 95%;
  }

  .video {
    width: 20rem;
    font-size: 2rem;
    margin: 0;
    border: 0.2px solid $purple;
    outline: 0.2px solid $purple;

    .buttonContainer {
      height: 1.7rem;
      display: flex;
      justify-content: space-between;

      button {
        display: block;
        padding: 0;
        width: 40%;
        border: none;
        background: none;
        color: $purple;
        cursor: pointer;
      }
    }

    p {
      color: $purple;
      margin: 0.2rem 0;
      text-align: center;
      padding: 0 0.5rem;
    }

    div {
      width: 100%;
      height: 15rem;
      margin: 0 auto;
      cursor: pointer;

      video {
        object-fit: cover;
      }

      @include mq900 {
        height: 12rem;
      }

      @include mq500 {
        height: 10rem;
      }
    }

    @include mq900 {
      width: 33%;
    }

    @include mq500 {
      width: 50%;
    }
  }

  @include mq500 {
    width: 100%;
  }
}
