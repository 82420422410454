@import "../../../styles/variables.scss";
@import "../../../styles/mediaQueries.scss";

.recipe {
  width: 50rem;
  font-size: 2rem;
  margin: 9rem auto 4rem;
  padding: 2rem;
  border: 1px solid $purple;
  border-radius: 0.5rem;
  position: relative;

  .buttonContainer {
    position: absolute;
    top: 1rem;
    right: 1.5rem;

    button {
      border: none;
      background: none;
      color: $purple;
      cursor: pointer;

      &:last-child {
        margin-left: 2rem;
      }
    }
  }

  .recipePhoto {
    width: 100%;
    margin: 0 auto;
    cursor: pointer;

    img {
      object-fit: contain;
    }
  }

  p {
    margin: 1rem 0;
    line-height: 2.5rem;

    &:first-of-type {
      margin: 0;
    }
    &:last-of-type {
      margin-bottom: 0;
    }

    @include mq500 {
      font-size: 2.4rem;
      line-height: 2.9rem;
    }
  }

  @include mq900 {
    width: 80%;
  }

  @include mq500 {
    width: 90%;
  }
}
