@import "../../styles/variables.scss";
@import "../../styles/mediaQueries.scss";

.videosPage {
  min-height: 100vh;

  .videosHeader {
    text-align: center;
    font-size: 4.5rem;
    padding: 7.5rem 0 1.5rem;
    color: $purple;

    p {
      margin: 0;
    }

    @include mq500 {
      font-size: 3.5rem;
    }
  }

  .videos {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 80%;
    margin: 0 auto;

    .videoGroup {
      font-size: 4.5rem;
      border: 1px solid $purple;
      margin: 0 0.2rem 3rem;
      border-radius: 0.5rem;

      a {
        display: block;
        width: 100%;
        color: $purple;
        text-decoration: none;
        padding: 2rem;

        @include mq500 {
          padding: 1rem;
        }
      }

      @include mq500 {
        font-size: 3.5rem;
        text-align: center;
      }
    }

    @include mq500 {
      width: 90%;
      flex-direction: column;
    }
  }
}
